const data = {
  real_estate_projects: [
    {
      project_name: 'Villas tipo town house Bavaro - Punta Cana',
      project_slug: 'projecto-de-villas-tipo-town-house-en-bavaro-punta-cana',
      logo: "/assets/images/logo.png",
      title: "Proyecto de villas estilo town house de 3 niveles con piscina y terraza privada",
      description: "<p>Complejo residencial de villas de estilo Town House, ideal para personas que desean invertir en la zona de Bávaro Punta Cana, y que tienen la visión de un estilo de vida relajado y tranquilo, disfrutando de los múltiples beneficios que ofrece esta ciudad costera.</p><p>El complejo incluye unas 28 villas de dos niveles para ser adquiridas en planos, con un sistema de financiamiento cómodo para los adquirientes y a ser recibidas en 12 meses a partir de la reserva.</p>",
      keywords: "inmobiliaria, real estate, propiedades, venta, alquiler, santo domingo, punta cana, bavaro, república dominicana",
      image_project: "/assets/villas-vera-cana/images/villas-town-house-bavaro-calle.jpeg",
      image_beach: "/assets/villas-vera-cana/images/playa-bavaro-punta-cana.jpg",
      images_renders: [
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-calle.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-piscina.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-cocina-comedor.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-cocina.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-sala.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-vista-dentro-fuera.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-piscina-view.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-piscina-aire.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-terraza-privada-1.jpg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-terraza-privada-2.jpg"
        },
        {
          image: "/assets/villas-vera-cana/images/villas-town-house-bavaro-terraza-privada-techo.jpg"
        }
      ],
      images_construction: [
        {
          image: "/assets/villas-vera-cana/images/proceso-construccion-villas-town-house-bavaro-frontal.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/proceso-construccion-villas-town-house-bavaro-piscina.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/proceso-construccion-villas-town-house-bavaro.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/proceso-construccion-villas-town-house-blocks.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/proceso-construccion-villas-town-house-bavaro-perfil.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/proceso-construccion-villas-town-house-bavaro-perfil2.jpeg"
        },
        {
          image: "/assets/villas-vera-cana/images/proceso-construccion-villas-town-house-bavaro-perfil3.jpeg"
        }
      ],
      ville_amenities: [
        '130m2 de construcción', 
        '180m2 de solar',
        'Sala / Cocina en el 1er nivel',
        '3 Habitaciones en el 2do nivel',
        'Terraza en el 3er nivel',
        '2.5 Baños',
        'Sala, Cocina, Comedor',
        'Piscina de 4.50 x 3.50 mts',
        '3 Parqueos',
      ],
      project_amenities: [
        'Residencial cerrado con seguridad 24/7',
        'Amplias áreas al aire libre para esparcimiento',
        'Portón eléctrico para acceso controlado.',
        'Excelente ubicación en la ciudad.',
      ],
      location: {
        title: 'Excelente ubicación',
        description: 'El complejo se encuentra en las inmediaciones del Boulevard Turístico del Este, el cual conecta las principales áreas de comercio, entretenimiento y turismo de la zona. Próximo a Down Town Punta Cana, y a solo 15 minutos de las principales playas de Bávaro y del Aeropuerto Internacional de Punta Cana.',
        latitude: '18.6933',
        longitude: '-68.4563',
        map_location: '/assets/villas-vera-cana/images/ubicacion-proyecto-villas-town-house-bavaro.jpg'
      },
      starting_price: "USD $185,000",
      reserve_price: "USD $2,000",
      delivery_time: '8 meses',
      address: '',
      phone: '809-605-0699',
      whatsapp: '809-605-0699',
      whatsapp_number: '18096050699',
      email: 'ventas@superhogar.net',
      facebook: 'https://www.facebook.com/SuperHogar/?ref=br_rs',
      twitter: '',
      instagram: 'https://www.instagram.com/superhogarrd',
      linkedin: '',
      youtube: 'https://www.youtube.com/channel/UCLzJpdrplA9Oy6K1tVf8ZHw',
      tiktok: '',
      // Add more data as needed
    }
  ]
};

export default data;
