import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";
import { Spinner } from 'react-bootstrap';

const QuickLeadForm = (projectData) => {
    useEffect(() => emailjs.init("OR-hFrEcD28bt5k1j"), []);
    
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    console.log('data', projectData);

    const { register, handleSubmit, formState: {errors} } = useForm();
    const handleError = () => {
        setAlertMessage('Debe completar todos los campos requeridos.');
        setShowAlert(true);
    };

    const registerOptions = {
        fullName: {
            required: 'El nombre es requerido',
            maxLength: {
                value: 30,
                message: 'El nombre no puede tener mas de 30 caracteres'
            },
            minLength: {
                value: 3,
                message: 'El nombre debe tener al menos 3 caracteres'
            }
        },
        phoneNumber: {
            required: 'El teléfono es requerido',
            pattern: {
                value: /^[0-9]*$/,
                message: 'El teléfono no es válido'
            }
        },
    };

    const sendEmail = async (data) => {
        const serviceId = "service_iks7h7a";
        const templateId = "template_0wkbfnh";

        const error = handleError();
        if (error) {
            setAlertMessage('Ha ocurrido un error, intente mas tarde o escriba al whatsapp 809-605-0699.');
            return;
        }
        
        try {
            setAlertMessage('Enviando mensaje...');
            setShowAlert(true);
            setIsSubmitting(true);
            await emailjs.send(serviceId, templateId, {
                fullName: data.fullName,
                phoneNumber: data.phoneNumber,
                projectName: projectData.projectData.title
            },
                'T_J-KCWbmYMA0ibuB'
            );
        } catch (error) {
            setAlertMessage('Ha ocurrido un error, intente mas tarde o escriba al whatsapp 809-605-0699.');
        } finally {
            setAlertMessage('El mensaje se ha enviado correctamente.');
            setIsSubmitting(false);
            setIsSubmitted(true);
        }
    };

    return (
        <Container className="justify-content-center align-items-center vh-auto my-5 py-4">
            <Row className='mb-3'>
                <div className="title-container mb-3 mb-md-5">
                    <h2 className="mx-sm-4 animate__animated animate__pulse">¿Quieres más información o visitar el apartamento modelo?</h2>
                </div>
            </Row>
            <Row className='align-items-center'>
                <Col sm={6}>
                    <Row className='mb-3'>
                    {showAlert && (
                        <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                            {alertMessage}
                        </Alert>
                    )}
                    {isSubmitted ? (
                        <Container className='text-center mt-sm-5 animate__animated animate__fadeInUp bg-light p-2'>
                            <h1 className='display-5'>Gracias por enviar sus datos</h1>
                            <p className='display-6'>En breve nos comunicaremos con usted.</p>
                        </Container>
                    ) : (
                        <Form className='text-start col-12 quick-lead-form px-sm-5' onSubmit={handleSubmit(sendEmail, handleError)}>
                            <Row>
                                <h5>Complete el formulario</h5>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col} sm={12} controlId="formGridName">
                                    <Form.Label as="legend">Nombre y Apellido</Form.Label>

                                    <Form.Control 
                                        type="text" 
                                        placeholder="Ingrese su nombre" 
                                        {...register("fullName", registerOptions.fullName)}
                                    /> 
                                    <small className="text-danger">
                                        {errors?.fullName && errors.fullName.message}
                                    </small>
                                </Form.Group>
                            </Row>
                            <Row className='mb-3'>
                                <Form.Group as={Col} sm={12} controlId="formGridPhone">
                                    <Form.Label as="legend">Número de contacto</Form.Label>
                                    <Form.Control 
                                        name="phoneNumber" 
                                        type="number"
                                        placeholder="Ingrese su número de contácto"
                                        {...register("phoneNumber", registerOptions.phoneNumber)}
                                    />
                                    <small className="text-danger">
                                        {errors?.phoneNumber && errors.phoneNumber.message}
                                    </small>
                                </Form.Group>

                            </Row>
                            <Row className='mb-3 justify-content-center'>
                                <Form.Group as={Row} sm={10} controlId="formSend">
                                    {isSubmitting ? <Spinner animation="border" variant="primary" /> : (
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                            Enviar
                                        </Button>
                                    )}
                                </Form.Group>
                            </Row>            
                    
                        </Form>
                    )}
                    </Row>
                </Col>
                <Col sm={6}>
                    <Row className='justify-content-center text-center'>
                        <Col sm={6}>
                            <ul className='py-3 py-md-5 px-3 rounded-5 text-md-start list-unstyled custom-list bg-light'>
                                <li>
                                <FontAwesomeIcon className='fa-2x' icon={faPhone} /> <span className='mx-4'> {projectData.projectData.phone}</span>
                                </li>
                                <li>
                                <FontAwesomeIcon className='fa-2x' icon={faWhatsapp} /> <span className='mx-4'> {projectData.projectData.whatsapp}</span>
                                </li>
                                <li>
                                <FontAwesomeIcon className='fa-2x' icon={faEnvelope} /> <span className='mx-4'> {projectData.projectData.email}</span>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default QuickLeadForm;
