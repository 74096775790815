import React from 'react';
import { Helmet } from "react-helmet";
import data from "../data/superhogar";
import projects from "../data/real-estate-all-data";
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>{data.title}</title>
                <meta name="description" content={data.description} />
                <meta name="keywords" content={data.keywords} />
                <meta property="og:title" content={data.title} />
                <meta property="og:type" content="article"/>
                <meta property="og:url" content="/" />
                <meta property="og:site_name" content="SuperHogar Inmobiliaria - Conecta con tu nuevo hogar o inversión" />
                <meta property="og:description" content={data.description} />
            </Helmet>
            <Container className="my-5 g-sm-0">
                <Row>
                    <h1 className="mx-sm-4 animate__animated animate__pulse mb-5 display-4">Proyectos para inversión o segunda vivienda en Santo Domingo y Punta Cana</h1>
                </Row>
                <Row>
                    {projects.real_estate_projects.map((project, index) => (
                    <Col key={index} sm={12} md={4} className="mt-4">
                        <Card className='border-0 mx-lg-4 animate__animated animate__fadeInUp'>
                            <Link to={`/proyectos-en-construccion/${project.project_slug}`}>
                                <Card.Img className='image-class' variant="top" src={project.image_project} />
                            </Link>
                            <Card.Body>
                                <Card.Title>{project.title}</Card.Title>
                                <Card.Text>
                                    Desde {project.starting_price.toLocaleString()}
                                </Card.Text>
                                <Link to={`/proyectos-en-construccion/${project.project_slug}`} className="btn btn-secondary text-white">
                                    Ver Projecto
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    ))}
                </Row>
            </Container>
            
        </>
    );
};

export default HomePage;
