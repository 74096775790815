// Create component for home page carousel
import React from "react";
import {Carousel, Image} from 'react-bootstrap';

const HomeCarousel = ({images}) => {
    const [index, setIndex] = React.useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    }


    return (
        <Carousel fade activeIndex={index} onSelect={handleSelect} indicators={false} className="mb-3">
            {images.map((item) => (
                <Carousel.Item key={item.id}>
                    <Image src={item.image} alt={item.alt} className="carousel-image img-fluid w-100 h-100" />
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

export default HomeCarousel;
