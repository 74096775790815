import React from "react";
import Card from "react-bootstrap/Card"; 
import Row from "react-bootstrap/Row"; 
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useParams } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import data from "../data/real-estate-all-data";
import { Helmet } from "react-helmet";
import CallToAction from "../components/CallToAction";
import PhotoGallery from "../components/PhotoGallery";
import HomeCarousel from "../components/HomeCarousel";
import QuickLeadForm from "../components/QuickLeadForm";

const RealStateProjectSlug = () => {
    const { pageIdSlug } = useParams();
    const filteredProject = data.real_estate_projects.find((service) => service.project_slug === pageIdSlug);
    return (
        <>
            <Helmet>
                <title>{filteredProject.project_name} - SuperHogar Inmobiliaria</title>
                <meta name="description" content={filteredProject.description} />
                <meta name="keywords" content={filteredProject.keywords} />
                <meta property="og:title" content={filteredProject.title} />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`/proyectos-en-construccion/${filteredProject.project_slug}`} />
                <meta property="og:site_name" content={`${filteredProject.project_name} - SuperHogar Inmobiliaria`} />
                <meta property="og:description" content={filteredProject.description} />
            </Helmet>
            <Navbar bg="light" expand="lg" className="mb-3 mb-md-5" sticky="top">
                <Container className="justify-content-center">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="justify-content-center" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                        <Nav className="justify-content-center" activeKey="/home">
                            <Nav.Item>
                                <Nav.Link className="btn" href="#caracteristicas">Características</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="btn" href="#amenidades">Amenidades</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="btn" href="#ubicacion">Ubicación</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="btn" href="#galeria">Galería de fotos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="btn" href="#proceso">Proceso Construcción</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className="mb-md-5">
                <Row className="justify-content-center px-5 px-md-0">
                    <h1 className="col-md-8 animate__animated animate__pulse mb-3">{filteredProject.title}</h1>
                </Row>
                <Row className="px-5 px-md-0">
                    <h2 className="h3 text-primary">Precio: {filteredProject.starting_price} / Reserva con: {filteredProject.reserve_price}</h2>
                </Row>
                <Row className="px-5 px-md-0">
                    <h3 className="h4 text-green mb-3 mb-md-5">Entrega en {filteredProject.delivery_time} después de la firma</h3>
                </Row>
                <Row className="align-items-center">
                    <Col md={6} className="g-0">
                        <HomeCarousel images={filteredProject.images_renders.slice(0, 3)} />
                    </Col>
                    <Col md={6}>
                        <div className="h5 px-4 px-md-0" dangerouslySetInnerHTML={{ __html: filteredProject.description }} />
                    </Col>
                </Row>
            </Container>
            <Container className="g-0 mb-md-5 py-md-5 bg-cover-image" fluid style={{backgroundImage: `url(${filteredProject.image_project})`, width: "100%", height: "auto"}}>
                <QuickLeadForm projectData={filteredProject} />
            </Container>
            <Container className="g-asd0 mb-5" id="caracteristicas">
                <Row className="align-items-center">
                    <Col className="mx-sm-4 col-12 col-sm-5">
                        <h2>Características</h2>
                        {filteredProject.ville_amenities.map((amenity, index) => (
                            <ListGroup key={index} variant="flush">
                                <ListGroup.Item>{amenity}</ListGroup.Item>
                            </ListGroup>
                        ))}
                    </Col>
                    <Col className="g-0 col-12 col-sm-6">
                        <HomeCarousel images={filteredProject.images_renders.slice(3, 8)} />
                    </Col>
                </Row>
            </Container>
            <Container className="mb-5 py-5 bg-cover-image" fluid style={{backgroundImage: `url(${filteredProject.image_beach})`, width: "100%", height: "auto"}}>
                <Container>
                    <Row id="amenidades">
                        <div className="title-container mb-3 mb-md-5">
                            <h2 className="mx-sm-4 animate__animated animate__pulse display-4 text-white">Amenidades del proyecto</h2>
                        </div>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Image className="img-fluid rounded-5 mb-3" src={`../../../${filteredProject.images_renders[10].image}`} />
                        </Col>
                        <Col className="mx-sm-4 col-sm-5 col-12">
                            <Card>
                                <Card.Header as="h5">
                                    Facilidades
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {filteredProject.project_amenities.map((amenity, index) => (
                                            <ListGroup key={index} variant="flush">
                                                <ListGroup.Item>{amenity}</ListGroup.Item>
                                            </ListGroup>
                                        ))}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center" id="ubicacion">
                        <div className="title-container my-3 my-md-5">
                            <h2 className="text-white display-4">Ubicación</h2>
                            <p className="text-white h5 mx-3 mx-md-5">{filteredProject.location.description}.</p>
                        </div>
                        <Image className="g-0 custom-img-style img-fluid mb-3" src={`../../../${filteredProject.location.map_location}`} />
                    </Row>
                </Container>
            </Container>
            <div id="galeria">
                <PhotoGallery images={filteredProject.images_renders} title="Galería de imágenes" />
            </div>
            <CallToAction title="¿Quieres conocer mas?" whatsapp={filteredProject.whatsapp_number} />
            <div id="proceso">
                <PhotoGallery images={filteredProject.images_construction} title="Proceso de construcción" />
            </div>
        </>
    );
}

export default RealStateProjectSlug;
