import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ContactUs from './pages/ContactUs';
import RealStateProjectSlug from './pages/RealStateProjectSlug';

import './scss/App.scss';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
      <Router>
        <ScrollToTop />
        <div className="App">
          {/* <StickyNavbar /> */}
          <Header />
          <Routes>
            <Route path="/proyectos-en-construccion/:pageIdSlug" element={<RealStateProjectSlug />} />
            <Route path="/contactenos" element={<ContactUs />} />
            <Route path="/" exact element={<HomePage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
  );
}

export default App;
