import React, { useState } from 'react';
import { Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import Masonry from 'react-masonry-css';

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

const PhotoGallery = ({ images, title }) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleImageLoad = () => {
    setIsLoading(false);
  }

  const imageElements = images.map((image, index) => (
      <div>
        {isLoading && <Spinner animation="border" />}
        <Image className='rounded-4' src={image.image} key={index} onLoad={handleImageLoad} style={{ display: isLoading ? 'none' : 'block'}} />
      </div>
  ));

  return (
    <Container>
        <Row>
            <Col>
                <h1 className='text-center display-5'>{title}</h1>
            </Col>
        </Row>
      <Row className='justify-content-center'>
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {imageElements}
        </Masonry>
      </Row>
    </Container>
  );
};

export default PhotoGallery;