const data = {
  logo: "/assets/superhogar/logo-superhogar.png",
  title: "SuperHogar Inmobiliaria",
  description: "Somos una empresa dedicada a la venta y alquiler de propiedades en la República Dominicana. Nuestro objetivo es brindar un servicio de calidad y confianza a nuestros clientes, ofreciendo las mejores opciones de inversión en el mercado inmobiliario.",
  keywords: "inmobiliaria, real estate, propiedades, venta, alquiler, santo domingo, punta cana, bavaro, república dominicana",
  homepage_carousel: [
    {
      id: 1,
      image: '../assets/paomakeup/images/services/microblading.jpg',
      alt: 'Permanent Makeup',
      title: 'Permanent Makeup',
      description: 'Te imaginas no tener que maquillarte a diario? Esto es posible con nuestros procedimientos de maquillaje semipermanente y podrás tener unas cejas perfectas y con un aspecto muy natural.'
    },
    {
      id: 2,
      image: '../assets/paomakeup/images/services/maquillaje-cliente.jpg',
      alt: 'Makeup',
      title: 'Maquillaje',
      description: 'Descubre el arte del maquillaje que resalta tu belleza intrínseca.\nYa sea para una ocasión especial o un look diario, te guiaremos hacia la perfección en cada trazo y color.'
    },
    {
      id: 3,
      image: '../assets/paomakeup/images/services/pestanas.jpg',
      alt: 'Beauty Lashes',
      title: 'Beauty Lashes',
      description: 'Eleva tu mirada con nuestras extensiones de pestañas. Desde un toque sutil hasta un glamour completo, cada pestaña cuenta una historia de elegancia.'
    },
    {
      id: 4,
      image: '../assets/paomakeup/images/services/salon-belleza.jpg',
      alt: 'Salón de Belleza',
      title: 'Salón de Belleza',
      description: 'Sumérgete en nuestro salón de belleza. Un santuario donde la relajación se combina con la transformación, servicios excepcionales que realzan tu belleza.'
    }
  ],
  address: '',
  phone: '809-605-0699',
  whatsapp: '809-605-0699',
  email: 'ventas@superhogar.net',
  facebook: 'https://www.facebook.com/SuperHogar/?ref=br_rs',
  twitter: '',
  instagram: 'https://www.instagram.com/superhogarrd',
  linkedin: '',
  youtube: 'https://www.youtube.com/channel/UCLzJpdrplA9Oy6K1tVf8ZHw',
  tiktok: '',
  // Add more data as needed
};

export default data;
