import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CallToAction = ({whatsapp, title}) => {
    return (
        <Container fluid className='my-5 py-3 py-sm-3 bg-light'>
            <Container className='my-25 bg-light'>
                <Row className="justify-content-md-center py-md-4">
                    <Col md="auto">
                        <h2>{title}</h2>
                    </Col>
                    <Col md="4">
                        <Link to={`https://wa.me/${whatsapp}`} className="btn btn-primary text-white border-0 px-3">Hablemos por whatsapp</Link>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default CallToAction;