import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import data from '../data/superhogar';

const Header = () => {
    return (
        <Container className='d-flex justify-content-center'>
            <Navbar className="gap-3 px-4 mt-4 mb-md-5" bg="transparent" expand="md">
                <Navbar.Brand href="/">
                    <img
                        src={data.logo}
                        className="align-top brand-logo"
                        alt={data.title}
                    />
                </Navbar.Brand>
                
            </Navbar>
        </Container>
    );
};

export default Header;
