import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {

    return (
        <Container fluid style={{backgroundColor: '#f8f9fa', textAlign: 'center', padding: '10px'}}>
            <footer className="text-center text-lg-start bg-body-tertiary text-muted">
                <div className="text-center p-4">
                    Desarrollado por:
                    <a className="text-reset fw-bold" href="https://www.coralanmultiservice.com/"> Coralan Multiservice</a>
                </div>
            </footer>
        </Container>
    );
};

export default Footer;
